// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
    overflow: hidden;
    width: 100%;

}

.scroll-container {
    display: flex;
    align-items: center;  /* This will vertically align the images in the center */
    white-space: nowrap;
    animation: scroll 17s linear infinite;

}

.scroll-container img {
    margin-right: 80px; /* Increased padding for more space between icons */
    width: 50px; /* Size of the icons */
    height: 100%; /* This will scale the height to fill the carousel */
    object-fit: contain; /* Ensures images are not cropped and fully visible */

}

@keyframes scroll {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

/* Faster animation on mobile devices */
@media (max-width: 768px) {
    .scroll-container {
        animation-duration: 4s; /* Faster */
    }
}`, "",{"version":3,"sources":["webpack://./src/landing/Carousel.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;;AAEf;;AAEA;IACI,aAAa;IACb,mBAAmB,GAAG,wDAAwD;IAC9E,mBAAmB;IACnB,qCAAqC;;AAEzC;;AAEA;IACI,kBAAkB,EAAE,mDAAmD;IACvE,WAAW,EAAE,sBAAsB;IACnC,YAAY,EAAE,oDAAoD;IAClE,mBAAmB,EAAE,qDAAqD;;AAE9E;;AAEA;IACI;QACI,+BAA+B;IACnC;IACA;QACI,mCAAmC;IACvC;AACJ;;AAEA,uCAAuC;AACvC;IACI;QACI,sBAAsB,EAAE,WAAW;IACvC;AACJ","sourcesContent":[".carousel {\n    overflow: hidden;\n    width: 100%;\n\n}\n\n.scroll-container {\n    display: flex;\n    align-items: center;  /* This will vertically align the images in the center */\n    white-space: nowrap;\n    animation: scroll 17s linear infinite;\n\n}\n\n.scroll-container img {\n    margin-right: 80px; /* Increased padding for more space between icons */\n    width: 50px; /* Size of the icons */\n    height: 100%; /* This will scale the height to fill the carousel */\n    object-fit: contain; /* Ensures images are not cropped and fully visible */\n\n}\n\n@keyframes scroll {\n    0% {\n        transform: translate3d(0, 0, 0);\n    }\n    100% {\n        transform: translate3d(-100%, 0, 0);\n    }\n}\n\n/* Faster animation on mobile devices */\n@media (max-width: 768px) {\n    .scroll-container {\n        animation-duration: 4s; /* Faster */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
